<template>
  <div>
    <div>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-tooltip.hover.v-warning
        title="Generar Entrega"
        variant="warning"
        @click="generarEntrega"
      >
        Generar Entrega
        <feather-icon icon="UserIcon" />
      </b-button>
    </div>
    <div>
      <b-modal
        id="modal-1"
        v-model="verModalAsignar"
        title="Datos de Entrega de Luminaria"
        ok-only
        hide-footer
        size="sm"
        ok-title="Accept"
        no-close-on-backdrop
        @hide="limpiarModal"
      >
        <b-overlay
          :show="loading"
        >
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <b-col cols="12">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Persona que recibirá"
                      rules="required"
                    >
                      <label>
                        Persona que recibirá:
                      </label>
                      <b-form-input
                        v-model="personaRecibe"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <label>Fecha de Entrega:</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Fecha de Entrega"
                    rules="required"
                  >
                    <b-input-group class="mb-1">
                      <b-form-input
                        id="timePickerEntrega"
                        v-model="fechaEntrega"
                        type="text"
                        placeholder="AAAA-MM-DD"
                        show-decade-nav
                        :state="errors.length > 0 ? false:null"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="fechaEntrega"
                          show-decade-nav
                          size="sm"
                          button-only
                          button-variant="primary"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <hr>
                </b-col>
                <b-col
                  style="margin-top: -25px"
                  class="d-flex justify-content-end"
                  cols="12"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mt-2 mr-1"
                    @click="validationForm"
                  >
                    Guardar
                    <feather-icon
                      icon="SaveIcon"
                      class="mr-50"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-overlay>
      </b-modal>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BOverlay,
  BForm,
  BInputGroupAppend,
  BInputGroup,
  BFormDatepicker,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import { updateCreateTicketEntregaLuminaria } from '@/utils/entregaLuminarias/ticketEntregaLuminariasUtils'
import { mensajeError, mensajeInformativo, notificacionError } from '@/utils/mensajes'
import { updateCreateEntregaLuminaria } from '@/utils/entregaLuminarias/entregaLuminariasUtils'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BOverlay,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormDatepicker,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    seleccionadas: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      loading: false,
      verModalAsignar: false,
      personaRecibe: null,
      fechaEntrega: null,
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods: {
    limpiarModal() {
      this.personaRecibe = null
      this.fechaEntrega = null
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.guardarEntrega()
        }
      })
    },
    async guardarEntrega() {
      this.loading = true
      try {
        const usuario = { ...this.usuario }
        const encargado = {
          id: usuario.id,
          nombre: `${usuario.firstName} ${usuario.firstSurname}`,
          email: usuario.email,
        }
        const seleccionadasLimpias = this.seleccionadas.map(luminaria => {
          const {
            vgtSelected, vgt_id, originalIndex, ...luminariaSinPropiedadesNoDeseadas
          } = luminaria
          return luminariaSinPropiedadesNoDeseadas
        })

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < seleccionadasLimpias.length; i++) {
          const luminaria = seleccionadasLimpias[i]
          luminaria.estado = 'PENDIENTE'
          // eslint-disable-next-line no-await-in-loop
          await updateCreateEntregaLuminaria(luminaria, 1)
        }

        const idsSeleccionados = this.seleccionadas.map(luminaria => luminaria.migracionId)
        const entrega = this.seleccionadas.map(luminaria => luminaria.id)
        const puntos = this.seleccionadas.map(luminaria => luminaria.puntoIluminacion)
        const body = {
          usuarioEncargado: encargado,
          fechaCreacion: new Date(),
          estado: 'PENDIENTE',
          nombreReceptor: this.personaRecibe,
          idsRelacionados: idsSeleccionados,
          idsEntrega: entrega,
          puntosIluminacion: puntos,
          fechaEntrega: this.fechaEntrega,
          companyId: this.usuario.company.id,
          seguimiento: [
            {
              tipo: 'Ingreso de Ticket',
              fechaCreacion: new Date(),
              usuarioIngreso: encargado,
            },
            {
              documentos: [],
              fechaCreacion: new Date(),
              tipo: 'Documento Firmado',
              usuarioIngreso: encargado,
            },
          ],
        }
        await updateCreateTicketEntregaLuminaria(body, 2)
        this.$emit('actualizar-datos')
        mensajeInformativo('Guardado', 'Entrega programada exitosamente!')
      } catch (error) {
        console.error(error)
        notificacionError('Algo ha salido mal, intenta nuevamente!')
      } finally {
        this.verModalAsignar = false
        this.loading = false
      }
    },
    generarEntrega() {
      const tienePendientes = this.seleccionadas.some(luminaria => luminaria.estado === 'PENDIENTE')
      if (tienePendientes) {
        mensajeError('No se puede asignar una luminaria que esté en estado pendiente')
      } else if (this.seleccionadas.length > 0) {
        this.verModalAsignar = true
      } else {
        mensajeError('Debe seleccionar una luminaria para entrega')
      }
    },
  },
}
</script>
