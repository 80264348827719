<template>
  <div>
    <b-row>
      <b-col
        cols="4"
      >
        <h6 class="text-primary">
          Número de Ticket
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="ticket.ticket"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="4"
      >
        <h6 class="text-primary">
          Técnico Encargado
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="ticket.tecnicoEncargado.nombre"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="4"
      >
        <h6 class="text-primary">
          Estado
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="ticket.estado"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="4"
      >
        <h6 class="text-primary">
          Poste
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="ticket.puntoIluminacion"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="4"
      >
        <h6 class="text-primary">
          Serie de Lámpara
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="ticket.seguimiento[0].datosPunto.serieLampara"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="4"
      >
        <h6 class="text-primary">
          Potencia de Lámpara
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="ticket.seguimiento[0].datosPunto.potencia"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col
        cols="12"
      >
        <h6 class="text-primary">
          Seguimiento
        </h6>
        <vue-good-table
          :columns="columns"
          :rows="ticketSeguimiento"
          style-class="vgt-table"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'detalle'">
              <ImagenShowReemplazo
                v-if="props.row.url"
                :clave="'seguimiento'"
                :ticket="ticket"
                :actualiza="false"
                :posicion-imagen="props.index"
                :origen="origenes.migracion"
              />
              <b-button
                v-if="ticket.files && ticket.files[0] && ticket.files[0].detalle && !props.row.url"
                variant="primary"
                @click="mostrarDetalle"
              >
                <feather-icon icon="ListIcon" />
              </b-button>
            </span>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
    <div>
      <b-modal
        id="modal-1"
        v-model="verModalDetalle"
        title="Detalle del Ticket de Entrega de Luminarias"
        ok-only
        hide-footer
        ok-title="Accept"
        no-close-on-backdrop
      >
        <modal-detalle-seguimiento-tickets-entrega
          :ticket="ticket.files && ticket.files[0] && ticket.files[0].detalle"
        />
      </b-modal>
    </div>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormInput, BFormGroup, BButton,
} from 'bootstrap-vue'
import ImagenShowReemplazo from '@/components/componenteImagenActualizar/ImagenShowReemplazo.vue'
import { origenes } from '@/utils/origenesImagen'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import ModalDetalleSeguimientoTicketsEntrega
from '@/components/entregaLuminarias/modalDetalleSeguimientoTicketsEntrega.vue'

export default {
  components: {
    ModalDetalleSeguimientoTicketsEntrega,
    ImagenShowReemplazo,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormGroup,
    VueGoodTable,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      verModalDetalle: false,
      camposTabla: [
        { key: 'tipo', label: 'Tipo' },
        { key: 'comentarios', label: 'Comentarios' },
        { key: 'detalle', label: '' },
      ],
      columns: [
        {
          label: 'Tipo',
          field: 'tipo',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Comentarios',
          field: 'comentarios',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Detalle',
          field: 'detalle',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    origenes() {
      return origenes
    },
    ticketSeguimiento() {
      let seguimientoCompleto = this.ticket.seguimiento ? [...this.ticket.seguimiento] : []

      if (this.ticket.files) {
        seguimientoCompleto = seguimientoCompleto.concat(this.ticket.files)
      }

      return seguimientoCompleto
    },
  },
  methods: {
    mostrarDetalle() {
      this.verModalDetalle = true
    },
  },
}
</script>
